import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startOfMonth, subMonths } from 'date-fns';

import {
  reportFilterStartAccountingDateSelector,
  reportFilterEndAccountingDateSelector,
  changeFilterStartAccountingDate,
  changeFilterEndAccountingDate,
  filterParametersDataSelector,
} from '../../../state';
import { constants, isEqualNullableDate } from '../../../helpers';
import DateRangeFlexSales, {
  DateRangeFlexSalesProps,
  DateRangeFlexSalesValue,
} from '../../DateRangeFlexSales';

type AccountingDateRangeFilterProps = Pick<DateRangeFlexSalesProps<Date>, 'spacing'> & {
  maxDaysBetween?: number; // Nueva prop opcional
};

const AccountingDateRangeFilter = (props: AccountingDateRangeFilterProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startDate = useSelector(reportFilterStartAccountingDateSelector);
  const endDate = useSelector(reportFilterEndAccountingDateSelector);
  const filterParameters = useSelector(filterParametersDataSelector);
  const minDate = startOfMonth(subMonths(new Date(), filterParameters?.filtersLimitMonths || 3));

  const dateValue: DateRangeFlexSalesValue<Date> = useMemo(
    () => [startDate.raw, endDate.raw] as DateRangeFlexSalesValue<Date>,
    [startDate.raw, endDate.raw]
  );

  const handleChange = useCallback(
    ([newStartAccountingDate, newEndAccountingDate]: DateRangeFlexSalesValue<Date>) => {
      if (!isEqualNullableDate(newStartAccountingDate, startDate.raw as Date)) {
        dispatch(changeFilterStartAccountingDate(newStartAccountingDate));
      } else if (!isEqualNullableDate(newEndAccountingDate, endDate.raw as Date))
        dispatch(changeFilterEndAccountingDate(newEndAccountingDate));
    },
    [dispatch, endDate, startDate]
  );

  return (
    <DateRangeFlexSales
      {...props}
      maxDate={constants.reportFilterRangeMaxDate}
      minDate={minDate}
      startText={t('toolbar.labels.fromDateAccounting')}
      endText={t('toolbar.labels.toDateAccounting')}
      value={dateValue}
      onChange={handleChange}
    />
  );
};

export default AccountingDateRangeFilter;
