import { useCallback } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeClosingFilterOnlyDifferences,
  reportClosingFilterOnlyDifferencesSelector,
} from '../state';

const FilterSwitch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const state = useSelector(reportClosingFilterOnlyDifferencesSelector);

  const handleChange = useCallback(() => {
    const newValue = !state;

    dispatch(changeClosingFilterOnlyDifferences(newValue));
  }, [dispatch, state]);

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={state} onChange={handleChange} />}
        label={
          state
            ? t('operativeStructure.onlyDifferences.onlyDifferences')
            : t('operativeStructure.onlyDifferences.all')
        }
      />
    </FormGroup>
  );
};

export default FilterSwitch;
