import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  reportCollectionsFilterSelector,
  reportFilterCountryCodeSelector,
  reportCashMovementReportSelector,
} from '../../state';
import { CashMovementReport } from '../../types';
import handlerFactory, { CashMovementReportDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<CashMovementReport, CashMovementReportDataGridContext>();

  const { t } = useTranslation();
  const title = t('cash-movement-report.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const reportCashMovementReportDataGridResource = useSelector(reportCashMovementReportSelector);
  const reportCollectionsFilters = useSelector(reportCollectionsFilterSelector);

  const { data, loading } = reportCashMovementReportDataGridResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);
  handler.setFilters(reportCollectionsFilters);

  return null;
};

const CashMovementReportDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default CashMovementReportDataGrid;
