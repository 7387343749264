import { EconomicAccountingReport } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface EconomicAccountingReportDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: EconomicAccountingReportDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<
    EconomicAccountingReport,
    EconomicAccountingReportDataGridContext
  >(initialContext, locale);

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'tenderName',
    type: 'string',
    title: ({ t }) => t('table.columns.tender_name'),
  });

  handler.addColumn({
    accessor: 'paymentType',
    type: 'string',
    title: ({ t }) => t('table.columns.payment_type'),
  });

  handler.addColumn({
    accessor: 'grossSale',
    type: 'number',
    title: ({ t }) => t('table.columns.venta_bruta'),
  });

  handler.addColumn({
    accessor: 'cashierDifference',
    type: 'number',
    title: ({ t }) => t('table.columns.cashier_difference'),
  });

  handler.addColumn({
    accessor: 'excess',
    type: 'number',
    title: ({ t }) => t('table.columns.exceso'),
  });

  handler.addColumn({
    accessor: 'pettyCashExtraction',
    type: 'number',
    title: ({ t }) => t('table.columns.petty_cash_extraction'),
  });

  handler.addColumn({
    accessor: 'theoricalBalanceReca',
    type: 'number',
    title: ({ t }) => t('table.columns.theorical_balance_reca'),
  });

  handler.addColumn({
    accessor: 'extraCash',
    type: 'number',
    title: ({ t }) => t('table.columns.extra_cash'),
  });

  handler.addColumn({
    accessor: 'difference',
    type: 'number',
    title: ({ t }) => t('table.columns.difference'),
  });

  return handler;
};
