import { collectionsReportsAxios } from '../config/axiosInstances';
import { FindPendingInterfacesRequest, FindPendingInterfacesResponse } from './SalesApi';

export interface FindReliefsRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  groupBy?: string;
}

export type FindReliefsResponse = {
  country: string;
  store?: string;
  business_date?: string;
  quantity: number;
  amount: number;
}[];

export interface FindPettyCashDifferenceRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  differenceType?: string;
  sentToOnedrive: boolean;
}

export type FindPettyCashDifferenceBaseResponse = {
  country: string;
  store: string;
  transfer_id: number;
  transfer_type_id: string;
  business_date: string;
  operation_date: string;
  transfer_time?: string;
  pos_num: number;
  shift_num: number;
  user_id: number;
  username: string;
  employee_id: number;
  employee_name: string;
  total_amt: number;
};

export type FindPettyCashDifferenceResponse = FindPettyCashDifferenceBaseResponse[];

export type FindPettyCashDifferenceByPaymentTypeRequest = FindPettyCashDifferenceRequest & {
  tenderName?: string;
};

export type FindPettyCashDifferenceByPaymentTypeResponse = (FindPettyCashDifferenceBaseResponse & {
  tender_name: string;
  amount: number;
  foreign_amount: number;
  fc_rate: number;
})[];

export interface FindEconomicAccountingReportRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  payment_type_ids?: string;
  only_diff: boolean;
  sentToOnedrive: boolean;
}

export type FindEconomicAccountingReportResponse = {
  country: string;
  store: string;
  business_date: string;
  tender_description: string;
  payment_type_id: string;
  gross_sales: number;
  cashier_difference: number;
  excess: number;
  petty_cash_extraction: number;
  deposit_amount_reca: number;
  extracash: number;
  diferencia: number;
}[];

export interface FindCashMovementReportRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  payment_type_ids?: string;
  movements_type?: string;
  sentToOnedrive: boolean;
}

export type FindCashMovementReportResponse = {
  pais: string;
  local: string;
  Fecha_de_Negocio: string;
  Nro_Referencia: number;
  Nro_Movimiento: number;
  Moneda: string;
  Tipo_Movimiento: string;
  Hora_Movimiento: string;
  Id_Usuario: string;
  Nombre_Usuario: string;
  Codigo_Movimiento: string;
  Deposito_Teorico: number;
  Recupero: number;
  Extracion_Caja_Chica: number;
  Compra_Cambio: number;
  Arqueo_Caja: number;
  Diferencia_Arqueo: number;
}[];

export default class {
  static async findReliefs(request: FindReliefsRequest) {
    return collectionsReportsAxios.get<FindReliefsResponse>(`/reliefs`, {
      params: request,
    });
  }

  static async findPettyCashDifference(request: FindPettyCashDifferenceRequest) {
    return collectionsReportsAxios.get<FindPettyCashDifferenceResponse>(`/petty_cash_difference`, {
      params: request,
    });
  }

  static async findPettyCashDifferenceByPaymentType(
    request: FindPettyCashDifferenceByPaymentTypeRequest
  ) {
    return collectionsReportsAxios.get<FindPettyCashDifferenceByPaymentTypeResponse>(
      `/petty_cash_difference_by_payment_type`,
      {
        params: request,
      }
    );
  }

  static async findPendingInterface(request: FindPendingInterfacesRequest) {
    return collectionsReportsAxios.get<FindPendingInterfacesResponse>(`/pending_interfaces`, {
      params: request,
    });
  }

  static async findEconomicAccountigReport(request: FindEconomicAccountingReportRequest) {
    return collectionsReportsAxios.get<FindEconomicAccountingReportResponse>(
      `/report_economic_contable`,
      {
        params: request,
      }
    );
  }

  static async findCashMovementReport(request: FindCashMovementReportRequest) {
    return collectionsReportsAxios.get<FindCashMovementReportResponse>(`/report_cash_movements`, {
      params: request,
    });
  }
}
