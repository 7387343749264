import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import {
  changeCollectionsFilterGroupBy,
  reportCollectionsFilterGroupBySelector,
  findCashMovementReportRequested,
  reportCashMovementReportLoadingSelector,
  reportCollectionsFilterStartBusinessDateSelector,
  reportCollectionsFilterEndBusinessDateSelector,
  reportFilterCountryCodeSelector,
} from '../state';

import {
  CountryFilter,
  PaymentTypesFilter,
  ReportFilterActions,
  ReportFiltersProps,
  ReportFilters,
  StoreFilter,
  MovementTypeFilter,
} from './ReportFilters';

import { AppPermissions, ReportFilterTenderGroupBy } from '../types';
import { BusinessDateRangeFilter } from './CollectionsFilters';
import { PermissionsProvider } from './Permissions';

const groupByConfig: ReportFiltersProps<ReportFilterTenderGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    storeAcronym: false,
    businessDate: false,
    tenderName: false,
  },
  action: changeCollectionsFilterGroupBy,
  selector: reportCollectionsFilterGroupBySelector,
};

const onedriveAction = () => findCashMovementReportRequested(true);

const CashMovementReportSearch = () => {
  const startDate = useSelector(reportCollectionsFilterStartBusinessDateSelector);
  const endDate = useSelector(reportCollectionsFilterEndBusinessDateSelector);
  const hasCountry = Boolean(useSelector(reportFilterCountryCodeSelector));
  const applyDisabled = !(startDate.accept && endDate.accept && hasCountry);
  const onedriveDisabled = applyDisabled;

  return (
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <ReportFilters
        groupByConfig={groupByConfig}
        loadingSelector={reportCashMovementReportLoadingSelector}
        applyAction={findCashMovementReportRequested}
        onedriveAction={onedriveAction}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <CountryFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={2}>
            <StoreFilter />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <BusinessDateRangeFilter spacing={1} />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <PaymentTypesFilter />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <MovementTypeFilter fullWidth />
          </Grid>
          <Grid item xs={12} md lg xl>
            <ReportFilterActions
              applyDisabled={applyDisabled}
              onedriveDisabled={onedriveDisabled}
            />
          </Grid>
        </Grid>
      </ReportFilters>
    </PermissionsProvider>
  );
};

export default CashMovementReportSearch;
