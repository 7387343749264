import { ReactNode, RefAttributes, useState, useEffect, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { addDays, min, isAfter } from 'date-fns';
import DatePickerFlexSales from './DatePickerFlexSales';

export type DateRangeFlexSalesValue<TDate> = [TDate | null, TDate | null];

export interface DateRangeFlexSalesProps<TDate> extends RefAttributes<HTMLDivElement> {
  startText: ReactNode;
  endText: ReactNode;
  minDate?: TDate;
  maxDate?: TDate;
  spacing?: number;
  value: DateRangeFlexSalesValue<TDate>;
  onChange: (value: DateRangeFlexSalesValue<TDate>) => void;
  maxDaysBetween?: number;
}

const DateRangeFlexSales = <TDate,>({
  startText,
  endText,
  minDate,
  maxDate,
  spacing,
  value,
  onChange,
  maxDaysBetween,
}: DateRangeFlexSalesProps<TDate>) => {
  const values = value || [null, null];
  const [startValue, setStartValue] = useState<TDate | null>(values[0]);
  const [endValue, setEndValue] = useState<TDate | null>(values[1]);

  const updateEndValue = useCallback(
    (newEndValue: TDate | null) => {
      setEndValue(newEndValue);
      onChange([startValue, newEndValue]);
    },
    [startValue, onChange]
  );

  const onChangeStartValue = (value: TDate | null) => {
    setStartValue(value);
    onChange([value, endValue]);
  };

  const onChangeEndValue = (value: TDate | null) => {
    updateEndValue(value);
  };

  // Revalidar endValue cuando startValue cambia
  useEffect(() => {
    if (startValue && endValue) {
      // Caso 1: Si el rango excede maxDaysBetween
      if (maxDaysBetween !== undefined) {
        const maxEndDate = addDays(startValue as unknown as Date, maxDaysBetween) as TDate;
        if (isAfter(endValue as unknown as Date, maxEndDate as unknown as Date)) {
          const newEndValue = null;
          updateEndValue(newEndValue);
          return;
        }
      }
      // Caso 2: Si startValue es mayor que endValue
      if (isAfter(startValue as unknown as Date, endValue as unknown as Date)) {
        const newEndValue = null;
        updateEndValue(newEndValue);
      }
    }
  }, [startValue, endValue, maxDaysBetween, updateEndValue]);

  return (
    <Stack direction="row" spacing={spacing}>
      <DatePickerFlexSales
        label={startText}
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        value={startValue}
        onChange={onChangeStartValue}
      />
      <DatePickerFlexSales
        label={endText}
        minDate={startValue || minDate || undefined}
        maxDate={
          startValue && maxDaysBetween !== undefined
            ? (min([
                addDays(startValue as unknown as Date, maxDaysBetween),
                maxDate as unknown as Date,
              ]) as TDate)
            : maxDate
        }
        value={endValue}
        disabled={!startValue}
        disableOpenPicker={!startValue}
        onChange={onChangeEndValue}
      />
    </Stack>
  );
};

export default DateRangeFlexSales;
