import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { MultiSelect, MultiSelectProps } from '../../selects';
import { CollectionsMovementsType } from '../../../types';
import {
  changeCollectionsFilterMovementsType,
  reportCollectionsFilterMovementTypeSelector,
} from '../../../state';

type MovementsTypeFilterProps = Pick<MultiSelectProps<string>, 'fullWidth'>;

const MovementTypeFilter = (props: MovementsTypeFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const movementsTypes = useSelector(reportCollectionsFilterMovementTypeSelector);
  const options = Object.values(CollectionsMovementsType);

  const handleChange = useCallback(
    (value: string[]) =>
      dispatch(changeCollectionsFilterMovementsType(value as CollectionsMovementsType[])),
    [dispatch]
  );

  return (
    <MultiSelect<string>
      {...props}
      options={options}
      value={movementsTypes}
      translationKey="operativeStructure.movementType"
      label={t('operativeStructure.movementType.label')}
      onChange={handleChange}
      selectAll
    />
  );
};

export default MovementTypeFilter;
