import { CashMovementReport } from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface CashMovementReportDataGridContext {
  t: (key: string | string[]) => string;
  country: string;
}

const initialContext: CashMovementReportDataGridContext = {
  t: () => '',
  country: '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<CashMovementReport, CashMovementReportDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'referenceNumber',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.reference_number'),
  });

  handler.addColumn({
    accessor: 'movementNumber',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.movement_number'),
  });

  handler.addColumn({
    accessor: 'paymentMethod',
    type: 'string',
    title: ({ t }) => t('cash-movement-report.columns.payment_method'),
  });

  handler.addColumn({
    accessor: 'movementType',
    type: 'string',
    title: ({ t }) => t('cash-movement-report.columns.movement_type'),
  });

  handler.addColumn({
    accessor: 'timeOfMovement',
    type: 'string',
    title: ({ t }) => t('cash-movement-report.columns.time_of_movement'),
  });

  handler.addColumn({
    accessor: 'userId',
    type: 'string',
    title: ({ t }) => t('cash-movement-report.columns.user_id'),
  });

  handler.addColumn({
    accessor: 'userName',
    type: 'string',
    title: ({ t }) => t('cash-movement-report.columns.user_name'),
  });

  handler.addColumn({
    accessor: 'movementCode',
    type: 'string',
    title: ({ t }) => t('cash-movement-report.columns.movement_code'),
  });

  handler.addColumn({
    accessor: 'theoreticalBalanceReca',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.theoretical_balance_reca'),
  });

  handler.addColumn({
    accessor: 'recovery',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.recovery'),
  });

  handler.addColumn({
    accessor: 'pettyCashExtraction',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.petty_cash_extraction'),
  });

  handler.addColumn({
    accessor: 'exchangePurchase',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.exchange_purchase'),
  });

  handler.addColumn({
    accessor: 'cashCount',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.cash_count'),
  });

  handler.addColumn({
    accessor: 'cashCountDifference',
    type: 'number',
    title: ({ t }) => t('cash-movement-report.columns.cash_count_difference'),
  });
  return handler;
};
