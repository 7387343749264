import { collectionsAxios } from '../config/axiosInstances';
import { FindClosingStatusRequest, FindClosingStatusResponse, MissingCauses } from '../types';
import { FindClosingRequest, FindClosingResponse } from './SalesApi';

export interface FindCollectionsMissingClosingDetailRequest {
  countryAcronym: string;
  storeAcronym?: string;
  accountingDate?: string;
}

export type FindCollectionsMissingClosingDetailResponse = {
  country: string;
  store: string;
  business_date: string;
  cause: string[];
}[];

export interface FindCollectionsMissingClosingCausesRequest {
  countryAcronym: string;
  storeAcronym: string;
  accountingDate?: string;
  cause: string;
}

export type CollectionsMissingTypeCauseResponse = {
  code: number;
  error_value: number;
};

export type CollectionsDifferencesCauseResponse = {
  transfer_type: string;
  sequence: number;
  close_transfer_qty: number;
  detail_transfer_qty: number;
};

export type FindCollectionsMissingClosingCausesResponse = {
  country: string;
  store: string;
  business_date: string;
  cause: string;
  details: CollectionsMissingTypeCauseResponse[] | CollectionsDifferencesCauseResponse[];
}[];

export default class {
  static async findClosingCollectionsStatus(request: FindClosingStatusRequest) {
    return collectionsAxios.get<FindClosingStatusResponse>(`/closing_status`, {
      params: request,
    });
  }

  static async findClosing(request: FindClosingRequest) {
    return collectionsAxios.get<FindClosingResponse>(`/missing_closing_detail`, {
      params: request,
    });
  }

  static async findCauseTypes() {
    return collectionsAxios.get<MissingCauses[]>(`/missing_closing_causes_types`);
  }

  static async findCollectionsMissingClosingCauses(
    request: FindCollectionsMissingClosingCausesRequest
  ) {
    return collectionsAxios.get<FindCollectionsMissingClosingCausesResponse>(
      `/missing_closing_causes`,
      {
        params: request,
      }
    );
  }
}
