import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  reportCollectionsFilterSelector,
  reportFilterCountryCodeSelector,
  reportEconomicAccountingReportSelector,
} from '../../state';
import { EconomicAccountingReport } from '../../types';
import handlerFactory, { EconomicAccountingReportDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<
    EconomicAccountingReport,
    EconomicAccountingReportDataGridContext
  >();

  const { t } = useTranslation();
  const title = t('economic-accounting-report.title');
  const country = useSelector(reportFilterCountryCodeSelector);
  const reportEconomicAccountingReportResource = useSelector(
    reportEconomicAccountingReportSelector
  );
  const reportCollectionsFilters = useSelector(reportCollectionsFilterSelector);

  const { data, loading } = reportEconomicAccountingReportResource;

  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);
  handler.setFilters(reportCollectionsFilters);

  return null;
};

const EconomicAccountingReportDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default EconomicAccountingReportDataGrid;
