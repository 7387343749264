import styled from '@emotion/styled';
import { EconomicAccountingReportDataGrid, EconomicAccountingReportSearch } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import { reportEconomicAccountingReportLoadingSelector } from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EconomicAccountingReportSearchContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const EconomicAccountingReport = () => (
  <Container>
    <EconomicAccountingReportSearchContainer>
      <FiltersLayoutHandler selector={reportEconomicAccountingReportLoadingSelector}>
        <EconomicAccountingReportSearch />
      </FiltersLayoutHandler>
    </EconomicAccountingReportSearchContainer>
    <EconomicAccountingReportDataGrid />
  </Container>
);

export default EconomicAccountingReport;
