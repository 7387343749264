/*
 * Es este archivo solo debe contener mappers de request/response para el consumo de las API.
 * Otros tipos de mapeos deberían ir en mappers.ts
 * Las funciones (que se exportan) deben tener el sufijo "RequestMap" o "ResponseMap" según corresponda
 */
import { format, parse, parseISO } from 'date-fns';
import lastDayOfMonth from 'date-fns/fp/lastDayOfMonth';
import {
  AnnulMonthClosingRequest,
  CloseMonthRequest,
  CollectionsDifferencesCauseResponse,
  CreateAccountingEntriesRequest,
  CreateReprocessRequest,
  DeleteAccountingEntriesRequest,
  DifferencesCauseResponse,
  EditAccountingParametersRequest,
  EditCompanyCodeRequest,
  FindAccountingEntriesRequest,
  FindAccountingEntriesResponse,
  FindAccountingParametersResponse,
  FindCancellationsRequest,
  FindCancellationsResponse,
  FindClosedMonthsRequest,
  FindClosingRequest,
  FindClosingResponse,
  FindCompanyCodesRequest,
  FindCompanyCodesResponse,
  FindDailySalesRequest,
  FindDailySalesResponse,
  FindDelayedStoresRequest,
  FindDelayedStoresResponse,
  FindDiscountsRequest,
  FindDiscountsResponse,
  FindGCsBaseRequest,
  FindGCsBaseResponse,
  FindGCsByProductRequest,
  FindGCsByProductResponse,
  FindGcsByPaymentTypesRequest,
  FindGcsByPaymentTypesResponse,
  FindGcsRequest,
  FindGcsResponse,
  FindGrossSaleRequest,
  FindGrossSaleResponse,
  FindMastersImportRequest,
  FindMastersImportResponse,
  FindMastersStatusDetailsRequest,
  FindMastersStatusDetailsResponse,
  FindMastersStatusResponse,
  FindMissingClosingCausesRequest,
  FindMissingClosingCausesResponse,
  FindMonthClosingRequest,
  FindMonthClosingResponse,
  FindOakBrookRequest,
  FindOakBrookResponse,
  FindOperationTypesResponse,
  FindTransactionTypeRequest,
  FindTransactionTypeResponse,
  FindPaymentTypesRequest,
  FindPaymentTypesResponse,
  FindPendingInterfacesRequest,
  FindPendingInterfacesResponse,
  FindPettyCashDifferenceBaseResponse,
  FindPettyCashDifferenceByPaymentTypeRequest,
  FindPettyCashDifferenceByPaymentTypeResponse,
  FindPettyCashDifferenceRequest,
  FindPettyCashDifferenceResponse,
  FindEconomicAccountingReportRequest,
  FindEconomicAccountingReportResponse,
  FindCashMovementReportRequest,
  FindCashMovementReportResponse,
  FindPosRequest,
  FindPosResponse,
  FindReliefsRequest,
  FindReliefsResponse,
  FindReportTenderRequest,
  FindReportTenderResponse,
  FindPendingInterfacesParametersResponse,
  FindReprocessParametersResponse,
  FindReprocessRequest,
  FindReprocessResponse,
  FindSalesByProductRequest,
  FindSalesByProductResponse,
  FindSalesJournalRequest,
  FindSalesJournalResponse,
  FindSalesRequest,
  FindSalesResponse,
  FindSalesTypesRequest,
  FindSalesTypesResponse,
  FindThirdPartySalesByItemsRequest,
  FindThirdPartySalesByItemsResponse,
  FindTimeBandRequest,
  FindTimeBandResponse,
  GetActiveCountriesResponse,
  GetOpStructuresResponse,
  GetParametersResponse,
  GetPosTypesResponse,
  MastersImportRequest,
  ValidationErrorCauseResponse,
  CreatePendingInterfacesRequest,
  ExecutionPendingInterfacesRequest,
  ExecutionPendingInterfacesResponse,
} from '../services';

import {
  AccountingEntriesFilter,
  AccountingEntry,
  AccountingEntryStatus,
  AccountingParameter,
  Agreement,
  AnnulMonthClosingForm,
  AppParameters,
  Cancellation,
  CancellationsFilter,
  CashMovementReport,
  CausesFilter,
  CloseMonthForm,
  Closing,
  ClosingStatus,
  CollectionsDifferencesCause,
  CollectionsFilter,
  Company,
  CompanyCode,
  CompanyCodesFilter,
  Country,
  CreateAccountingEntriesForm,
  CreateReprocessForm,
  DailySale,
  DelayedStores,
  DeleteAccountingEntriesForm,
  DifferencesCause,
  Discount,
  EditAccountingParameterForm,
  EditCompanyCodeForm,
  EconomicAccountingReport,
  FindClosingStatusRequest,
  FindClosingStatusResponse,
  FindClosingStatusTotalRequest,
  GCsByProduct,
  Gcs,
  GcsBase,
  GcsByPaymentTypes,
  GcsFilter,
  GrossSale,
  HourRange,
  Management,
  MasterData,
  MasterImportExecutionForm,
  MasterStatusDetail,
  MastersImport,
  MastersImportDetailFilter,
  MastersStatus,
  MissingCauses,
  MissingClosingCollectionsCauses,
  MissingClosingSalesCauses,
  MonthClosing,
  MonthClosingFilter,
  MonthClosingStatus,
  OakBrook,
  OakBrookFilter,
  OpStructures,
  OpStructuresFilter,
  OperationType,
  TransactionType,
  PaymentType,
  PendingInterfaces,
  PendingInterfacesFilter,
  PettyCashDifference,
  PettyCashDifferenceByPaymentType,
  PodOptionType,
  PosType,
  Region,
  RegionalManagement,
  Relief,
  ReportFilter,
  Reprocess,
  ReprocessFilter,
  ReprocessParameters,
  PendingInterfacesParameters,
  Sale,
  SaleType,
  SalesByProduct,
  SalesJournalFcs,
  SalesJournalGcs,
  SalesJournalNcs,
  SalesJournalThirdParty,
  Stores,
  Supervision,
  TenderReport,
  ThirdPartySalesByItems,
  TimeBand,
  ValidationErrorCause,
  CreatePendingInterfacesForm,
  ExecutionStatusPendingInterfaces,
  ExecutionStatusPendingInterfacesFilter,
} from '../types';

import { masterSort, undefinedIfEmpty } from './utls';

export const dateRequestMap = (date: Date | null | undefined) =>
  date ? format(date, 'yyyy-MM-dd') : undefined;

export const dateRangeRequestMap = (from: Date | null | undefined, to: Date | null | undefined) => {
  if (!from && !to) return undefined;
  const fromStr = from ? format(from, 'yyyy-MM-dd') : '';
  const toStr = to ? format(to, 'yyyy-MM-dd') : '';
  return `${fromStr},${toStr}`;
};

export const dateResponseMap = (date: string | null | undefined) =>
  date ? parse(date, 'yyyy-MM-dd', new Date()) : undefined;

export const dateTimeResponseMap = (date: string | null | undefined) =>
  date ? parseISO(date) : undefined;

export const podRequestMap = (pod: PodOptionType[]): string | undefined => {
  if (pod.length === 1) return pod[0] as string;
  return undefined;
};

export const masterDataResponseMap = (
  opStructuresResponse: GetOpStructuresResponse,
  posTypesResponse: GetPosTypesResponse,
  federativeEntitiesResponse: Record<string, string[]>
): MasterData => {
  const opStructuresMap = (opStructuresResponse: GetOpStructuresResponse): OpStructures => {
    const opStructures = opStructuresResponse.map(i => ({
      divisionId: i.division_id,
      division: i.division,
      countryId: i.country_id,
      country: i.country,
      regionalMgmtId: i.regional_mgmt_id,
      regionalMgmt: i.regional_mgmt,
      regionId: i.region_id,
      region: i.region,
      mgmtId: i.mgmt_id,
      mgmt: i.mgmt,
      supervisionId: i.supervision_id,
      supervision: i.supervision,
      storeEntityId: i.store_entity_id,
      storeAcronym: i.store_acronym,
      companyId: i.company_id,
      companyName: i.company_name,
      agreementId: i.agreement_id,
      agreementType: i.agreement_type,
    }));
    return opStructures;
  };

  const opStructures = opStructuresMap(opStructuresResponse);

  const countryMap = (item: GetOpStructuresResponse[0]): Country => ({
    id: item.country_id.toString(),
    label: item.country,
    countryCode: item.country,
    countryId: item.country_id.toString(),
    divisionId: item.division_id,
    divisionName: item.division,
  });

  const companyMap = (item: GetOpStructuresResponse[0], countries: Country[]): Company => ({
    countries: countries.some(i => i.countryCode === item.country)
      ? countries
      : [...countries, countryMap(item)],
    companyId: item.company_id,
    companyName: item.company_name,
  });

  const storeMap = (item: GetOpStructuresResponse[0]): Stores => ({
    ...countryMap(item),
    agreementType: item.agreement_type,
    agreementId: item.agreement_id,
    companyName: item.company_name,
    companyId: item.company_id,
    regionalMgmt: item.regional_mgmt,
    regionalMgmtId: item.regional_mgmt_id,
    region: item.region,
    regionId: item.region_id,
    mgmtId: item.mgmt_id,
    mgmt: item.mgmt,
    supervisionId: item.supervision_id,
    supervision: item.supervision,
    storeId: item.store_entity_id,
    storeAcronym: item.store_acronym,
  });

  const posTypeMap = (item: GetPosTypesResponse[0]): PosType => ({
    segmentDesc: item.segment_description,
    segmentId: item.segment_id,
  });

  const values = opStructuresResponse.reduce(
    (prev, curr) => ({
      divisions: [...prev.divisions, curr.division_id],
      countries: {
        ...prev.countries,
        [curr.country_id]: countryMap(curr),
      },
      stores: [...prev.stores, storeMap(curr)],
      companies: {
        ...prev.companies,
        [curr.company_id]: companyMap(curr, prev.companies[curr.company_id]?.countries || []),
      },
    }),
    {
      countries: {} as Record<string, Country>,
      divisions: [] as string[],
      stores: [] as Stores[],
      companies: {} as Record<number, Company>,
    }
  );

  const getRegionalManagements = (opStructures: OpStructures): RegionalManagement[] => {
    const regionalManagements: RegionalManagement[] = [];
    opStructures.forEach(({ countryId, ...i }) => {
      if (
        regionalManagements.findIndex(item => item.id === i.regionalMgmtId) === -1 &&
        i.regionalMgmtId !== null
      ) {
        regionalManagements.push({
          label: i.regionalMgmt,
          id: i.regionalMgmtId,
          divisionName: i.division,
          countryCode: i.country,
          countryId: countryId.toString(),
          regionalMgmt: i.regionalMgmt,
          regionalMgmtId: i.regionalMgmtId,
          divisionId: i.divisionId,
        });
      }
    });
    return regionalManagements;
  };

  const getRegions = (opStructures: OpStructures): Region[] => {
    const regions: Region[] = [];
    opStructures.forEach(({ countryId, ...i }) => {
      if (regions.findIndex(r => r.id === i.regionId) === -1 && i.regionId !== null) {
        regions.push({
          label: i.region,
          id: i.regionId,
          divisionName: i.division,
          countryCode: i.country,
          countryId: countryId.toString(),
          regionId: i.regionId,
          regionalMgmt: i.regionalMgmt,
          regionalMgmtId: i.regionalMgmtId,
          divisionId: i.divisionId,
          region: i.region,
        });
      }
    });
    return regions;
  };

  const getManagements = (opStructures: OpStructures): Management[] => {
    const managements: Management[] = [];
    opStructures.forEach(({ countryId, ...i }) => {
      if (managements.findIndex(m => m.id === i.mgmtId) === -1 && i.mgmtId !== null) {
        managements.push({
          label: i.mgmt,
          id: i.mgmtId,
          divisionName: i.division,
          countryCode: i.country,
          countryId: countryId.toString(),
          mgmtId: i.mgmtId,
          mgmt: i.mgmt,
          region: i.region,
          regionId: i.regionId,
          regionalMgmt: i.regionalMgmt,
          regionalMgmtId: i.regionalMgmtId,
          divisionId: i.divisionId,
        });
      }
    });
    return managements;
  };

  const getSupervisions = (opStructures: OpStructures): Supervision[] => {
    const supervisions: Supervision[] = [];
    opStructures.forEach(({ countryId, ...i }) => {
      if (
        supervisions.findIndex(s => s.id === i.supervisionId) === -1 &&
        i.supervisionId !== null
      ) {
        supervisions.push({
          label: i.supervision,
          id: i.supervisionId,
          divisionName: i.division,
          countryCode: i.country,
          countryId: countryId.toString(),
          supervision: i.supervision,
          supervisionId: i.supervisionId,
          mgmtId: i.mgmtId,
          mgmt: i.mgmt,
          regionId: i.regionId,
          region: i.region,
          regionalMgmt: i.regionalMgmt,
          regionalMgmtId: i.regionalMgmtId,
          divisionId: i.divisionId,
        });
      }
    });
    return supervisions;
  };

  const getAgreements = (opStructures: OpStructures): Agreement[] => {
    const agreements: Agreement[] = [];
    opStructures.forEach(({ countryId, ...i }) => {
      if (agreements.findIndex(s => s.id === i.agreementId) === -1 && i.agreementId !== null) {
        agreements.push({
          label: i.agreementId !== null ? i.agreementType : 'null',
          id: i.agreementId,
          divisionName: i.division,
          countryCode: i.country,
          countryId: countryId.toString(),
          agreementId: i.agreementId,
          agreementType: i.agreementType,
          divisionId: i.divisionId,
        });
      }
    });
    return agreements;
  };

  const result: MasterData = {
    divisions: [...new Set(values.divisions)],
    countries: Object.values(values.countries),
    stores: values.stores,
    posTypes: posTypesResponse.map(posTypeMap),
    companies: Object.values(values.companies),
    regionalManagements: getRegionalManagements(opStructures),
    regions: getRegions(opStructures),
    managements: getManagements(opStructures),
    supervisions: getSupervisions(opStructures),
    agreements: getAgreements(opStructures),
    federativeEntities: federativeEntitiesResponse,
  };

  return result;
};

export const activeCountriesResponseMap = (response: GetActiveCountriesResponse): Country[] =>
  response.map(i => ({
    id: i.country_id,
    label: i.country,
    divisionId: i.division_id || '',
    divisionName: i.division || '',
    countryId: i.country_id,
    countryCode: i.country,
  }));

export const findPosRequestMap = (
  country: string,
  store: Stores,
  startAccountingDate: Date | null,
  endAccountingDate: Date | null
): FindPosRequest => ({
  countryAcronym: country,
  storeAcronym: store.storeAcronym,
  accountingDateRange: dateRangeRequestMap(startAccountingDate, endAccountingDate) as string,
});

export const findPosResponseMap = (response: FindPosResponse): string[] => response;

export const findSalesTypesRequestMap = (country: string): FindSalesTypesRequest => ({
  countryAcronym: country,
});

export const findSalesTypesResponseMap = (response: FindSalesTypesResponse): SaleType[] =>
  response.map(i => ({ saleTypeId: i.sale_type_id, saleTypeDescription: i.sale_type_description }));

export const findPaymentTypeRequestMap = (countryId: string): FindPaymentTypesRequest => ({
  countryId,
});

export const findPaymentTypeResponseMap = (response: FindPaymentTypesResponse): PaymentType[] =>
  response.map(i => ({
    paymentType: i.payment_type,
    paymentTypeId: i.payment_type_id,
    tenderId: i.tender_id,
    shortName: i.short_name,
    tenderNameBr: `${i.tender_id} - ${i.short_name}`,
  }));

export const findClosingStatusRequestMap = (
  fromDt: Date,
  toDt: Date,
  countries: string[]
): FindClosingStatusRequest => ({
  countryAcronym: countries.join(),
  businessDateRange: dateRangeRequestMap(fromDt, toDt) as string,
});

export const findClosingStatusTotalRequestMap = (
  fromDt: Date,
  toDt: Date,
  country: string,
  stores: Stores[]
): FindClosingStatusTotalRequest => ({
  countryAcronym: country,
  businessDateRange: dateRangeRequestMap(fromDt, toDt) as string,
  storeAcronym: undefinedIfEmpty(stores.map(i => i.storeAcronym).join()),
});

export const findClosingStatusResponseMap = (
  response: FindClosingStatusResponse
): ClosingStatus[] =>
  response
    .map(i => ({
      country: i.country,
      fromDt: dateResponseMap(i.from_dt) as Date,
      toDt: dateResponseMap(i.to_dt) as Date,
      closeError: i.close_error,
      closeErrorPercent: 0,
      closeOk: i.close_ok,
      closeOkPercent: 0,
    }))
    .sort((a, b) => (a.country > b.country ? 1 : -1));

export const findSalesRequestMap = (
  filters: ReportFilter,
  opStructuresFilters: OpStructuresFilter,
  sentToOnedrive: boolean
): FindSalesRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    filters.startBusinessDate.accept,
    filters.endBusinessDate.accept
  ),
  accountingDateRange: dateRangeRequestMap(
    filters.startAccountingDate.accept,
    filters.endAccountingDate.accept
  ),
  operationDateRange: dateRangeRequestMap(
    filters.startOperationDate.accept,
    filters.endOperationDate.accept
  ),
  saleType: undefinedIfEmpty(filters.salesTypes.map(s => s.saleTypeId).join()),
  // TODO: Validar si esto es correcto, ya que se está enviado la descripción del segmento en vez del ID
  posType: undefinedIfEmpty(filters.segments.map(i => i.segmentDesc).join()),
  posNum: undefinedIfEmpty(filters.pos.join()),
  timeRange: undefinedIfEmpty(filters.hourRange.map(i => i.hour).join()),
  timeBand: undefinedIfEmpty(filters.timeBand.join()),
  regionalMgmtId: undefinedIfEmpty(opStructuresFilters.regionalMgmt.map(i => i.id).join()),
  regionId: undefinedIfEmpty(opStructuresFilters.region.map(i => i.id).join()),
  mgmtId: undefinedIfEmpty(opStructuresFilters.management.map(i => i.id).join()),
  supervisionId: undefinedIfEmpty(opStructuresFilters.supervision.map(i => i.id).join()),
  agreementId: undefinedIfEmpty(opStructuresFilters.agreement.map(i => i.id).join()),
  companyId: undefinedIfEmpty(opStructuresFilters.company.map(i => i.companyId).join()),
  groupBy: undefinedIfEmpty(filters.groupBy.join()),
  pod: podRequestMap(filters.pod),
  sentToOnedrive,
  accountingEntriesFields: filters.accountingEntriesFields,
});

export const findReportTenderRequestMap = (
  filters: ReportFilter,
  opStructuresFilters: OpStructuresFilter,
  sentToOnedrive: boolean
): FindReportTenderRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  accountingDateRange: dateRangeRequestMap(
    filters.startAccountingDate.accept,
    filters.endAccountingDate.accept
  ),
  operationDateRange: dateRangeRequestMap(
    filters.startOperationDate.accept,
    filters.endOperationDate.accept
  ),
  businessDateRange: dateRangeRequestMap(
    filters.startBusinessDate.accept,
    filters.endBusinessDate.accept
  ),
  saleType: undefinedIfEmpty(filters.salesTypes.map(s => s.saleTypeId).join()),
  posType: undefinedIfEmpty(filters.segments.map(i => i.segmentDesc).join()),
  posNum: undefinedIfEmpty(filters.pos.join()),
  timeRange: undefinedIfEmpty(filters.hourRange.map(i => i.hour).join()),
  timeBand: undefinedIfEmpty(filters.timeBand.join()),
  paymentTypeId: undefinedIfEmpty(filters.paymentTypes.map(s => s.paymentTypeId).join()),
  regionalMgmtId: undefinedIfEmpty(opStructuresFilters.regionalMgmt.map(i => i.id).join()),
  regionId: undefinedIfEmpty(opStructuresFilters.region.map(i => i.id).join()),
  mgmtId: undefinedIfEmpty(opStructuresFilters.management.map(i => i.id).join()),
  supervisionId: undefinedIfEmpty(opStructuresFilters.supervision.map(i => i.id).join()),
  agreementId: undefinedIfEmpty(opStructuresFilters.agreement.map(i => i.id).join()),
  companyId: undefinedIfEmpty(opStructuresFilters.company.map(i => i.companyId).join()),
  groupBy: undefinedIfEmpty(filters.tenderGroupBy.join()),
  operationType: undefinedIfEmpty(filters.operationType.map(i => i.id).join()),
  sentToOnedrive,
});

export const findSalesResponseMap = (response: FindSalesResponse): Sale[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    businessDate: dateResponseMap(i.business_date) as Date,
    businessWeekDay: dateResponseMap(i.business_date) as Date,
    accountingDate: dateResponseMap(i.accounting_date) as Date,
    accountingWeekDay: dateResponseMap(i.accounting_date) as Date,
    operationDate: dateResponseMap(i.operation_date) as Date,
    operationWeekDay: dateResponseMap(i.operation_date) as Date,
    saleTypeWh: i.sale_type_wh,
    posType: i.pos_type,
    posNum: i.pos_num,
    timeBand: i.time_band,
    dayPart: i.day_part,
    regionalMgmtId: i.regional_mgmt_id,
    regionId: i.region_id,
    mgmtId: i.mgmt_id,
    supervisionId: i.supervision_id,
    agreementId: i.agreement_id,
    companyId: i.company_id,
    netSalePos: i.net_sale_pos,
    tax1Pos: i.tax1_pos,
    tax2Pos: i.tax2_pos,
    tax3Pos: i.tax3_pos,
    grossSalePos: i.gross_sale_pos,
    netSaleFinalPos: i.net_sale_final_pos,
    netSaleThirdParty: i.net_sale_third_party,
    tax1ThirdParty: i.tax1_third_party,
    tax2ThirdParty: i.tax2_third_party,
    tax3ThirdParty: i.tax3_third_party,
    grossSaleThirdParty: i.gross_sale_third_party,
    netSaleNp: i.net_sale_np,
    tax1Np: i.tax1_np,
    tax2Np: i.tax2_np,
    tax3Np: i.tax3_np,
    grossSaleNp: i.gross_sale_np,
    netSaleFinalNp: i.net_sale_final_np,
    netSalePnp: i.net_sale_pnp,
    tax1Pnp: i.tax1_pnp,
    tax2Pnp: i.tax2_pnp,
    tax3Pnp: i.tax3_pnp,
    grossSalePnp: i.gross_sale_pnp,
    netSaleFinalPnp: i.net_sale_final_pnp,
    netSaleP: i.net_sale_p,
    tax1P: i.tax1_p,
    tax2P: i.tax2_p,
    tax3P: i.tax3_p,
    grossSaleP: i.gross_sale_p,
    netSaleFinalP: i.net_sale_final_p,
    gcs: i.gcs,
    gcsAvg: i.gcs_avg,
    accountingStoreId: i.accounting_store_id,
    storeId: i.store_id,
    discountSalePos: i.discount_sale_pos,
    caixaMapSales: i.mapacaixasales,
    aliquot0Sales: i.aliquota0sales,
    loyalty: i.loyalty,
    icms: i.icms,
    pis: i.pis,
    cofins: i.cofins,
    brindesSalesHmb: i.brindessales_hmb,
    icmsBrindesHmb: i.icmsbrindes_hmb,
    pisBrindesHmb: i.pisbrindes_hmb,
    cofinsBrindesHmb: i.cofinsbrindes_hmb,
    deliveryRate: i.tasadeentrega,
    icmsBrindesT: i.icmsbrindes_t,
    pisBrindesT: i.pisbrindes_t,
    cofinsBrindesT: i.cofinsbrindes_t,
    brindesSalesV: i.brindessales_v,
    icmsBrindesV: i.icmsbrindes_v,
    pisBrindesV: i.pisbrindes_v,
    cofinsBrindesV: i.cofinsbrindes_v,
  }));

export const findReportTenderResponseMap = (response: FindReportTenderResponse): TenderReport[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    businessDate: i.business_date ? (dateResponseMap(i.business_date) as Date) : undefined,
    accountingDate: i.accounting_date ? (dateResponseMap(i.accounting_date) as Date) : undefined,
    operationDate: i.operation_date ? (dateResponseMap(i.operation_date) as Date) : undefined,
    saleTypeWh: i.sale_type_wh,
    posType: i.pos_type,
    posNum: i.pos_num,
    timeBand: i.time_band,
    dayPart: i.day_part,
    regionalMgmtId: i.regional_mgmt_id,
    regionId: i.region_id,
    mgmtId: i.mgmt_id,
    supervisionId: i.supervision_id,
    agreementId: i.agreement_id,
    companyId: i.company_id,
    averagePrice: i.average_price,
    extraCash: i.extra_cash,
    promotion: i.promotion,
    saleOriginalForeignCurrency: i.sale_original_foreign_currency,
    totalGrossSales: i.total_gross_sales,
    totalNetSales: i.total_net_sales,
    paymentTypeId: i.payment_type_id,
    operationTypeName: i.operation_type_name,
    tenderName: i.tender_name,
    tenderDescription: i.tender_description,
  }));

const findGcsBaseRequestMap = (
  filters: ReportFilter,
  gcsFilters: GcsFilter,
  sentToOnedrive: boolean
): FindGCsBaseRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  accountingDateRange: dateRangeRequestMap(
    gcsFilters.startAccountingDate.accept,
    gcsFilters.endAccountingDate.accept
  ),
  businessDateRange: dateRangeRequestMap(
    gcsFilters.startBusinessDate.accept,
    gcsFilters.endBusinessDate.accept
  ),
  operationDateRange: dateRangeRequestMap(
    gcsFilters.startOperationDate.accept,
    gcsFilters.endOperationDate.accept
  ),
  transactionType: undefinedIfEmpty(filters.transactionType.map(i => i.id).join()),
  // TODO: Validar si esto es correcto, ya que se está enviando la descripción del segmento en vez del ID
  posType: undefinedIfEmpty(filters.segments.map(i => i.segmentDesc).join()),
  posNum: undefinedIfEmpty(filters.pos.join()),
  timeRange: undefinedIfEmpty(filters.hourRange.map(i => i.hour).join()),
  sentToOnedrive,
});

const findGcsBaseResponseMap = (item: FindGCsBaseResponse): GcsBase => ({
  accountingDate: dateResponseMap(item.accounting_date) as Date,
  businessDate: dateResponseMap(item.business_date) as Date,
  operationDate: dateResponseMap(item.operation_date) as Date,
  country: item.country,
  store: item.store,
  storeId: item.store_id,
  countryId: item.country_id,
  posNum: item.pos_num,
  posType: item.pos_type,
  transactionType: item.transaction_type,
  endOfSaleTimestamp: dateTimeResponseMap(item.end_of_sale_timestamp) as Date,
  dayPart: item.day_part,
  receiptNum: item.receipt_num,
  invoiceType: item.invoice_type,
  invoiceKind: item.invoice_kind,
  fiscalPosCode: item.fiscal_pos_code,
  saleReceipt: item.fiscal_code5,
  receiptClass: item.fiscal_code4,
  fiscalName1: item.fiscal_name1,
  fiscalName2: item.fiscal_name2,
  fiscalAddress1: item.fiscal_address1,
  fiscalAddress2: item.fiscal_address2,
  transactionAmt: item.transaction_amt,
  taxAmt: item.tax_amt,
  netAmt: item.net_amt,
  specialSaleOrderId: item.special_sale_order_id,
  specialSaleType: item.special_sale_type,
  specialSaleMethod: item.special_sale_method,
  operatorId: item.operator_id,
  managerId: item.manager_id,
  saleKey: item.sale_key,
  plName: item.wild_card_alfanum1,
});

export const findGcsRequestMap = (
  filters: ReportFilter,
  gcsFilters: GcsFilter,
  sentToOnedrive: boolean
): FindGcsRequest => ({
  ...findGcsBaseRequestMap(filters, gcsFilters, sentToOnedrive),
  saleDiscount: undefinedIfEmpty(filters.saleDiscount.map(i => i.idDiscount).join()),
  pod: podRequestMap(filters.pod),
  discountsJoin: undefinedIfEmpty(filters.discountsJoin.join()),
});

export const findGcsResponseMap = (response: FindGcsResponse): Gcs[] =>
  response.map(i => ({
    ...findGcsBaseResponseMap(i),
    fiscalReceiptNum: i.fiscal_receipt_num,
    fiscalRegisterId: i.fiscal_register_id,
    discountOnTotalAmt: i.discount_on_total_amt,
    saleDiscountId: i.sale_discount_id,
    saleDiscountDescription: i.sale_discount_description,
    saleDiscountRate: i.sale_discount_rate,
    saleDiscountAmount: i.sale_discount_amount,
    accountingStoreId: i.accounting_store_id,
    clientMcId: i.wild_card_alfanum4,
    program: i.wild_card_alfanum5,
    digitalChannel: i.wild_card_numeric1,
  }));

export const findGcsByPaymentTypesRequestMap = (
  filters: ReportFilter,
  gcsFilters: GcsFilter,
  sentToOnedrive: boolean
): FindGcsByPaymentTypesRequest => ({
  ...findGcsBaseRequestMap(filters, gcsFilters, sentToOnedrive),
  operationType: undefinedIfEmpty(filters.operationType.map(i => i.id).join()),
  tenderName: undefinedIfEmpty(filters.paymentTypes.map(i => i.tenderId).join()),
});

export const findGcsByPaymentTypesResponseMap = (
  response: FindGcsByPaymentTypesResponse
): GcsByPaymentTypes[] =>
  response.map(i => ({
    ...findGcsBaseResponseMap(i),
    id: i.id,
    saleDescription: i.sale_description,
    seqNumber: i.seq_no,
    beginOfSaleTimestamp: dateTimeResponseMap(i.begin_of_sale_timestamp) as Date,
    specialSaleType: i.special_sale_type,
    tenderName: i.tender_name,
    tenderType: i.tender_type,
    amount: i.amount,
    operationTypeName: i.operation_type_name,
    fiscalReceiptNum: i.fiscal_receipt_num,
    tipAmount: i.wild_card_numeric1,
    idOperation: i.epayment_id,
    numCoupon: i.epayment_coupon,
    idPosnet: i.epayment_terminal,
    posnet: i.epayment_store_id,
    numCard: i.epayment_card_num,
  }));

export const findGCsByProductRequestMap = (
  filters: ReportFilter,
  gcsFilters: GcsFilter,
  sentToOnedrive: boolean
): FindGCsByProductRequest => ({
  ...findGcsBaseRequestMap(filters, gcsFilters, sentToOnedrive),
  plu: undefinedIfEmpty(filters.plu.join()),
  saleType: undefinedIfEmpty(filters.salesTypes.map(s => s.saleTypeId).join()),
  saleDiscount: undefinedIfEmpty(filters.saleDiscount.map(i => i.idDiscount).join()),
  itemDiscount: undefinedIfEmpty(filters.itemDiscount.map(i => i.idDiscount).join()),
  discountsJoin: undefinedIfEmpty(filters.discountsJoin.join()),
});

export const findGCsByProductResponseMap = (response: FindGCsByProductResponse): GCsByProduct[] =>
  response.map(item => ({
    ...findGcsBaseResponseMap(item),
    saleTypeWh: item.sale_type_wh,
    plu: item.plu,
    fiscalReceiptNum: item.fiscal_receipt_num,
    itemsQty: item.items_qty,
    itemStatusName: item.item_status_name,
    itemTotalAmt: item.item_total_amt,
    nonProductPrice: item.non_product_price,
    saleDescription: item.sale_description,
    shortName: item.short_name,
    taxAmt: item.tax_amt,
    taxNpAmt: item.tax_np_amt,
    thirdpartyMenuItem: item.thirdparty_menu_item,
    transactionType: item.transaction_type,
    unitPriceAmt: item.unit_price_amt,
    ventaNpNetaIva: item.venta_np_neta_iva,
    seqNumber: item.seq_no,
    discountOnTotalAmt: item.discount_on_total_amt,
    clientMcId: item.wild_card_alfanum4,
    program: item.wild_card_alfanum5,
    digitalChannel: item.wild_card_numeric1,
    saleDiscountId: item.sale_discount_id,
    saleDiscountDescription: item.sale_discount_description,
    saleDiscountRate: item.sale_discount_rate,
    saleDiscountAmount: item.sale_discount_amount,
    itemDiscountId: item.item_discount_id,
    itemDiscountDescription: item.item_discount_description,
    itemDiscountRate: item.item_discount_rate,
    itemDiscountAmount: item.item_discount_amount,
    beginOfSaleTimestamp: dateTimeResponseMap(item.begin_of_sale_timestamp) as Date,
  }));

export const findReprocessParametersResponseMap = (
  response: FindReprocessParametersResponse
): ReprocessParameters => ({
  types: response.types,
  statuses: response.statuses,
  ibGroups: response.ibGroups.map(ibg => ({
    countryId: ibg.country_id,
    groupId: ibg.group_id,
    groupName: ibg.group_name,
  })),
});

export const findReprocessRequestMap = (filters: ReprocessFilter): FindReprocessRequest => ({
  countryAcronym: filters.countryCode,
  reprocessType: filters.reprocessType,
  status: filters.status,
  storeAcronym: filters.storeAcronym,
  federativeEntity: filters.federativeEntity,
  businessDate: dateRequestMap(filters.businessDate.accept),
});

export const findReprocessResponseMap = (response: FindReprocessResponse): Reprocess[] =>
  response.map(i => ({
    country: i.country,
    justification: i.justification,
    status: i.status,
    store: i.store,
    type: i.reprocess_type,
    user: i.user,
    businessDate: dateResponseMap(i.business_date) as Date,
    executionDate: dateTimeResponseMap(i.execution_date) as Date,
    creationDate: dateTimeResponseMap(i.creation_date) as Date,
    federativeEntity: i.federative_entity || '',
  }));

export const createReprocessRequestMap = (
  request: CreateReprocessForm
): CreateReprocessRequest => ({
  countryAcronym: request.country || '',
  justification: request.justification,
  reprocessType: request.type,
  storeAcronym: request.store || '',
  stores: (request.stores || []).map(i => i.storeAcronym),
  businessDate: dateRequestMap(request.businessDate) as string,
  businessDateFrom: dateRequestMap(request.businessDateFrom) as string,
  businessDateTo: dateRequestMap(request.businessDateTo) as string,
  groupIds: (request.groupIds || []).map(i => i.groupId),
  federativeEntities: request.federativeEntity ? [request.federativeEntity] : [],
  allFailed: request.allFailed || false,
});

export const findClosingRequestMap = (
  countries: string[],
  stores: Stores[],
  startDate: Date,
  endDate: Date,
  causeTypes: MissingCauses[],
  federativeEntities: string[],
  franchiseTypes: string[]
): FindClosingRequest => ({
  countryAcronym: countries.join(),
  storeAcronym: undefinedIfEmpty(stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(startDate, endDate),
  causes: undefinedIfEmpty(causeTypes.join()),
  federativeEntities: undefinedIfEmpty(federativeEntities.join()),
  franchiseTypes: undefinedIfEmpty(franchiseTypes.join()),
});

const causeResponseMap = (causes: string[], api: 'sales' | 'collections'): string[] =>
  causes.map(cause => {
    if (cause === MissingCauses.CLOSING_NOT_INFORMED && api === 'sales') {
      return MissingCauses.SALES_CLOSING_NOT_INFORMED;
    }
    if (cause === MissingCauses.CLOSING_NOT_INFORMED && api === 'collections') {
      return MissingCauses.COLL_CLOSING_NOT_INFORMED;
    }
    return cause;
  });

export const findClosingResponseMap = (
  response: FindClosingResponse,
  api: 'sales' | 'collections'
): Closing[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    cause: causeResponseMap(i.cause, api),
    federativeEntity: i.federative_entity,
    franchiseType: i.franchise_type,
    date: dateResponseMap(i.business_date) as Date,
  }));

export const findMissingClosingCausesRequestMap = (
  causesFilters: CausesFilter
): FindMissingClosingCausesRequest => ({
  countryAcronym: causesFilters.country,
  storeAcronym: causesFilters.store,
  accountingDate: dateRequestMap(causesFilters.date.accept),
  cause: causesFilters.cause,
});

export const salesMissingClosingCausesResponseDetailsMap = (
  cause: MissingCauses,
  details: (ValidationErrorCauseResponse | DifferencesCauseResponse)[]
): DifferencesCause[] | ValidationErrorCause[] => {
  let mappedDetails: ValidationErrorCause[] | DifferencesCause[] = [];
  if (cause === MissingCauses.DIFFERENCE_BETWEEN_CLOSING_AND_DETAIL) {
    mappedDetails = (details as DifferencesCauseResponse[]).map(i => ({
      posNum: i.pos_num,
      sequence: i.sequence,
      closeAmt: i.close_amt,
      closeGcQty: i.close_gc_qty,
      detailAmt: i.detail_amt,
      detailGcQty: i.detail_gc_qty,
    }));
  } else {
    const errors: ValidationErrorCause[] = [];
    (details as ValidationErrorCauseResponse[]).forEach(i =>
      i.pending_errors?.forEach(p =>
        errors.push({
          errorType: i.error_type,
          code: i.code,
          posNumber: i.pos_num,
          sequence: i.sequence,
          elementType: i.element_type,
          errorDetails: p,
        })
      )
    );
    mappedDetails = errors;
  }
  return mappedDetails;
};

export const findMissingClosingSalesCausesResponseMap = (
  response: FindMissingClosingCausesResponse
): MissingClosingSalesCauses | undefined =>
  response
    ? {
        country: response.country,
        store: response.store,
        date: dateResponseMap(response.business_date) as Date,
        cause: response.cause as MissingCauses,
        details: salesMissingClosingCausesResponseDetailsMap(
          response.cause as MissingCauses,
          response.details
        ),
      }
    : undefined;

export const collectionsMissingClosingCausesResponseDetailsMap = (
  cause: MissingCauses,
  details: (ValidationErrorCauseResponse | CollectionsDifferencesCauseResponse)[]
) => {
  let mappedDetails: ValidationErrorCauseResponse[] | CollectionsDifferencesCause[] = [];
  if (cause === MissingCauses.DIFFERENCE_BETWEEN_CLOSING_AND_DETAIL) {
    mappedDetails = (details as CollectionsDifferencesCauseResponse[]).map(i => ({
      transferType: i.transfer_type,
      sequence: i.sequence,
      closeTransferQty: i.close_transfer_qty,
      detailTransferQty: i.detail_transfer_qty,
    }));
  } else {
    const errors: ValidationErrorCause[] = [];
    (details as ValidationErrorCauseResponse[]).forEach(i =>
      i.pending_errors?.forEach(p =>
        errors.push({
          errorType: i.error_type,
          code: i.code,
          posNumber: i.pos_num,
          elementType: i.element_type,
          sequence: i.sequence,
          errorDetails: p,
        })
      )
    );
    mappedDetails = errors;
  }
  return mappedDetails;
};

export const findMissingClosingCollectionsCausesResponseMap = (
  response: FindMissingClosingCausesResponse
): MissingClosingCollectionsCauses | undefined =>
  response
    ? {
        country: response.country,
        store: response.store,
        date: dateResponseMap(response.business_date) as Date,
        cause: response.cause as MissingCauses,
        details: collectionsMissingClosingCausesResponseDetailsMap(
          response.cause as MissingCauses,
          response.details
        ),
      }
    : undefined;

export const findMissingClosingTotalResponseMap = (response: FindClosingStatusResponse): number =>
  response[0].close_error;

export const findCompanyCodesRequestMap = (
  filters: CompanyCodesFilter
): FindCompanyCodesRequest => ({ countryId: filters.countryId });

export const findCompanyCodesResponseMap = (response: FindCompanyCodesResponse): CompanyCode[] =>
  response.map(i => ({
    countryId: i.country_id,
    name: i.name,
    codeWh: parseInt(i.company_code_wh, 10),
    codeEbs: i.company_code_ebs,
    currency: i.currency,
    ledger: i.ledger,
    classCode: i.class_code,
    companyOakId: i.company_oak_id,
    isAccounted: i.is_accounted,
  }));

export const companyCodesEditRequestMap = (form: EditCompanyCodeForm): EditCompanyCodeRequest => ({
  countryId: form.countryId,
  companyCodeEbs: form.codeEbs,
  companyCodeWh: form.codeWh.toString(),
  currency: form.currency,
  ledger: form.ledger,
  classCode: form.classCode as number,
  companyOakId: form.companyOakId as number,
  isAccounted: Boolean(form.isAccounted),
  name: form.name,
});

export const findAccountingParametersResponseMap = (
  response: FindAccountingParametersResponse
): AccountingParameter[] =>
  response.map(item => ({
    countryId: item.country_id,
    journalType: item.journal_type,
    source: item.source,
    category: item.category,
    categoryAlias: item.category_alias,
    frequency: item.frequency,
    details: item.details
      .sort((a, b) => a.journal_subtype.localeCompare(b.journal_subtype))
      .map(i => ({
        journalSubtype: i.journal_subtype,
        account: i.account,
        subAccount: i.sub_account,
      })),
  }));

export const accountingParametersEditRequestMap = (
  form: EditAccountingParameterForm
): EditAccountingParametersRequest => ({
  countryId: form.countryId,
  journalType: form.journalType,
  frequency: form.frequency,
  details: form.details.map(i => ({
    journalSubtype: i.journalSubtype,
    account: i.account,
    subAccount: i.subAccount,
  })),
});

export const findAccountingEntriesRequestMap = (
  filters: AccountingEntriesFilter
): FindAccountingEntriesRequest => ({
  countryId: filters.country?.countryId,
  journalType: filters.journalType,
  status: filters.status,
  accountingMonth: dateRequestMap(filters.accountingMonth.accept),
});

export const findAccountingEntriesResponseMap = (
  response: FindAccountingEntriesResponse
): AccountingEntry[] =>
  response.map(i => ({
    countryId: i.country_id,
    id: i.id,
    journalType: i.journal_type,
    journalGroup: i.journal_group,
    status: i.status as AccountingEntryStatus,
    accountingMonth: dateResponseMap(i.accounting_month) as Date,
    createdDate: dateTimeResponseMap(i.created_date) as Date,
  }));

export const accountingEntriesCreateRequestMap = (
  form: CreateAccountingEntriesForm,
  countryCode: string
): CreateAccountingEntriesRequest => ({
  countryId: form.countryId?.toString() as string,
  journalGroup: form.journalGroup,
  accountingMonth: dateRequestMap(form.accountingMonth) as string,
  countryCode,
});

export const accountingEntriesDeleteRequestMap = (
  form: DeleteAccountingEntriesForm
): DeleteAccountingEntriesRequest => ({
  countryId: form.countryId,
  journalGroup: form.journalGroup,
  accountingMonth: dateRequestMap(form.accountingMonth) as string,
  comments: form.comments,
});

export const findMonthClosingRequestMap = (
  filters: MonthClosingFilter
): FindMonthClosingRequest => ({
  countryAcronym: filters.country,
});

export const findMonthClosingResponseMap = (response: FindMonthClosingResponse): MonthClosing[] =>
  response.map(i => ({
    country: i.country,
    accountingMonth: dateResponseMap(i.accounting_month) as Date,
    status: i.status as MonthClosingStatus,
    justification: i.justification,
    createdBy: i.created_by,
    creationDate: dateResponseMap(i.creation_date) as Date,
    updatedBy: i.updated_by,
    updateDate: dateResponseMap(i.update_date) as Date,
  }));

export const monthClosingCloseRequestMap = (form: CloseMonthForm): CloseMonthRequest => ({
  countryAcronym: form.country,
  accountingMonth: dateRequestMap(lastDayOfMonth(form.accountingMonth)) as string,
  createdBy: form.createdBy,
});

export const monthClosingAnnulRequestMap = (
  form: AnnulMonthClosingForm
): AnnulMonthClosingRequest => ({
  countryAcronym: form.country,
  accountingMonth: dateRequestMap(form.accountingMonth) as string,
  updatedBy: form.updatedBy,
  justification: form.justification,
});

export const findClosedMonthsRequestMap = (
  countries: string[],
  accountingMonth: Date
): FindClosedMonthsRequest => ({
  countryAcronym: countries.join(),
  accountingMonth: dateRequestMap(accountingMonth) as string,
});

export const findDailySalesRequestMap = (countries: string[]): FindDailySalesRequest => ({
  countryAcronym: countries.join(),
});

export const findDailySalesResponseMap = (response: FindDailySalesResponse): DailySale[] => {
  const dailySales: DailySale[] = [];
  response.forEach(i => {
    if (i) {
      dailySales.push({
        country: i.country,
        delayedStores: 0,
      });
    }
  });
  return dailySales;
};

export const findDelayedStoresRequestMap = (countries: string[]): FindDelayedStoresRequest => ({
  countryAcronym: countries.join(),
});

export const findDelayedStoresResponseMap = (
  response: FindDelayedStoresResponse
): DelayedStores[] => {
  const delayedStores: DelayedStores[] = [];
  response.forEach(i => {
    const lastSale = Math.abs(new Date(i.last_sale_dttm) as unknown as number);
    const currentTime = Math.abs(new Date(i.current_dttm) as unknown as number);
    const difference = (currentTime - lastSale) / 36e5;
    const hours = 2;
    if (difference > hours) {
      delayedStores.push({
        country: i.country,
        store: i.store,
        lastSale: dateTimeResponseMap(i.last_sale_dttm) as Date,
      });
    }
  });
  return delayedStores;
};

export const findHourRangeRequestMap = (country: string): FindTimeBandRequest => ({
  countryAcronym: country,
});

export const findHourRangeResponseMap = (response: FindTimeBandResponse): HourRange[] =>
  response.map(i => ({ hour: i.day_part_id }));

export const findTimeBandRequestMap = (country: string): FindTimeBandRequest => ({
  countryAcronym: country,
});

export const findTimeBandResponseMap = (response: FindTimeBandResponse): TimeBand[] =>
  [...new Set(response.map(i => i.band))].map(i => ({ description: i }));

export const findGrossSaleRequestMap = (
  filters: ReportFilter,
  collectionsFilters: CollectionsFilter,
  paymentTypeId: number | undefined
): FindGrossSaleRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    collectionsFilters.startBusinessDate.accept,
    collectionsFilters.endBusinessDate.accept
  ),
  paymentTypeId: paymentTypeId?.toString(),
  groupBy: undefinedIfEmpty(collectionsFilters.groupBy.join()),
});

export const findGrossSaleResponseMap = (response: FindGrossSaleResponse): GrossSale[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    businessDate: dateResponseMap(i.business_date) as Date,
    grossSale: i.gross_sale,
  }));

export const findReliefsRequestMap = (
  filters: ReportFilter,
  collectionsFilters: CollectionsFilter
): FindReliefsRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    collectionsFilters.startBusinessDate.accept,
    collectionsFilters.endBusinessDate.accept
  ),
  groupBy: undefinedIfEmpty(collectionsFilters.groupBy.join()),
});

export const findReliefsResponseMap = (
  collectionsFilters: CollectionsFilter,
  dataGrossSale: FindGrossSaleResponse,
  dataReliefs: FindReliefsResponse
): Relief[] => {
  const reliefs: Relief[] = [];
  dataReliefs.forEach(relief => {
    let grossSaleList = dataGrossSale;
    if (collectionsFilters.groupBy.includes('storeAcronym')) {
      grossSaleList = grossSaleList.filter(grossSale => grossSale.store === relief.store);
    }
    if (collectionsFilters.groupBy.includes('businessDate')) {
      grossSaleList = grossSaleList.filter(
        grossSale => grossSale.business_date === relief.business_date
      );
    }
    if (grossSaleList.length > 0) {
      reliefs.push({
        country: relief.country,
        store: relief.store,
        businessDate: dateResponseMap(relief.business_date) as Date,
        quantity: relief.quantity,
        amount: relief.amount,
        grossSale: grossSaleList[0].gross_sale,
      });
    }
  });
  return reliefs;
};

export const findCancellationsRequestMap = (
  filters: ReportFilter,
  cancellationsFilters: CancellationsFilter
): FindCancellationsRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  accountingDateRange: dateRangeRequestMap(
    cancellationsFilters.startAccountingDate.accept,
    cancellationsFilters.endAccountingDate.accept
  ),
  groupBy: undefinedIfEmpty(cancellationsFilters.groupBy.join()),
});

export const findCancellationsResponseMap = (response: FindCancellationsResponse): Cancellation[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    accountingDate: dateResponseMap(i.accounting_date) as Date,
    operation: i.operation,
    amount: i.amount,
    quantity: i.quantity,
    grossSale: i.pos_gross_sale,
    percent: i.percent.toFixed(2),
  }));

export const findOperationTypesResponseMap = (
  response: FindOperationTypesResponse
): OperationType[] =>
  response.map(i => ({
    id: i.operation_type,
    name: i.operation_type_name,
  }));

export const findTransactionTypeRequestMap = (
  filters: ReportFilter
): FindTransactionTypeRequest => ({
  countryAcronym: filters.countryCode,
});

export const findTransactionTypeResponseMap = (
  response: FindTransactionTypeResponse
): TransactionType[] =>
  response.map(i => ({
    id: i,
  }));

export const findOakBrookRequestMap = (filters: OakBrookFilter): FindOakBrookRequest => ({
  countryAcronym: filters.country?.countryCode as string,
  monthYear: dateRequestMap(filters.month.accept) as string,
});

export const findOakBrookResponseMap = (response: FindOakBrookResponse): OakBrook[] =>
  response.map(i => ({
    classCode: i.class_code,
    companyOakId: i.company_oak_id,
    countryId: i.country_id,
    date: dateResponseMap(i.date) as Date,
    gcs: i.gcs,
    oakStoreId: i.oak_store_id,
    sales: i.sales,
  }));

export const findSalesByProductRequestMap = (
  filters: ReportFilter,
  opStructuresFilters: OpStructuresFilter,
  sentToOnedrive: boolean
): FindSalesByProductRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    filters.startBusinessDate.accept,
    filters.endBusinessDate.accept
  ),
  accountingDateRange: dateRangeRequestMap(
    filters.startAccountingDate.accept,
    filters.endAccountingDate.accept
  ),
  operationDateRange: dateRangeRequestMap(
    filters.startOperationDate.accept,
    filters.endOperationDate.accept
  ),
  posType: undefinedIfEmpty(filters.segments.map(i => i.segmentDesc).join()),
  posNum: undefinedIfEmpty(filters.pos.join()),
  timeRange: undefinedIfEmpty(filters.hourRange.map(i => i.hour).join()),
  timeBand: undefinedIfEmpty(filters.timeBand.join()),
  plu: undefinedIfEmpty(filters.plu.join()),
  regionalMgmtId: undefinedIfEmpty(opStructuresFilters.regionalMgmt.map(i => i.id).join()),
  regionId: undefinedIfEmpty(opStructuresFilters.region.map(i => i.id).join()),
  mgmtId: undefinedIfEmpty(opStructuresFilters.management.map(i => i.id).join()),
  supervisionId: undefinedIfEmpty(opStructuresFilters.supervision.map(i => i.id).join()),
  agreementId: undefinedIfEmpty(opStructuresFilters.agreement.map(i => i.id).join()),
  companyId: undefinedIfEmpty(opStructuresFilters.company.map(i => i.companyId).join()),
  groupBy: undefinedIfEmpty(filters.salesProductGroupBy.join()),
  saleType: undefinedIfEmpty(filters.salesTypes.map(s => s.saleTypeId).join()),
  sentToOnedrive,
});

export const findSalesByProductResponseMap = (
  response: FindSalesByProductResponse
): SalesByProduct[] =>
  response.map(item => ({
    accountingDate: dateResponseMap(item.accounting_date) as Date,
    businessDate: dateResponseMap(item.business_date) as Date,
    operationDate: dateResponseMap(item.operation_date) as Date,
    store: item.store,
    posNum: item.pos_num,
    posType: item.pos_type,
    dayPart: item.day_part,
    timeBand: item.time_band,
    saleTypeWh: item.sale_type_wh,
    plu: item.plu,
    shortName: item.short_name,
    regionalMgmtId: item.regional_mgmt_id,
    regionId: item.region_id,
    mgmtId: item.mgmt_id,
    supervisionId: item.supervision_id,
    agreementId: item.agreement_id,
    companyId: item.company_id,
    quantitySold: item.quantity_sold,
    quantitySoldLocal: item.quantity_sold_local,
    quantitySoldDelivery: item.quantity_sold_delivery,
    quantityWaste: item.quantity_waste,
    quantityPromo: item.quantity_promo,
    quantityMeal: item.quantity_meal,
    netSalePos: item.net_sale_pos,
    grossSalePos: item.gross_sale_pos,
    netSaleFinalPos: item.net_sale_final_pos,
    netSaleThirdParty: item.net_sale_third_party,
    grossSaleThirdParty: item.gross_sale_third_party,
    netSaleNp: item.net_sale_np,
    grossSaleNp: item.gross_sale_np,
    netSaleFinalNp: item.net_sale_final_np,
    netSalePnp: item.net_sale_pnp,
    grossSalePnp: item.gross_sale_pnp,
    netSaleFinalPnp: item.net_sale_final_pnp,
    netSaleP: item.net_sale_p,
    grossSaleP: item.gross_sale_p,
    netSaleFinalP: item.net_sale_final_p,
  }));

export const filterParametersResponseMap = (response: GetParametersResponse): AppParameters => ({
  filtersLimitMonths: response.parameters?.filtersLimitMonths,
  dashboardTimer: response.parameters?.dashboardTimer,
  storeExtraInfo: Object.values(response.storeExtraInfo)
    .flat()
    .map(i => ({
      storeId: parseInt(i.store_id, 10),
      name: i.name,
      baseStoreId: i.base_tore_id !== undefined ? parseInt(i.base_tore_id, 10) : i.base_tore_id,
    })),
});

export const findThirdPartySalesByItemsRequestMap = (
  filters: ReportFilter,
  sentToOnedrive: boolean
): FindThirdPartySalesByItemsRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  accountingDateRange: dateRangeRequestMap(
    filters.startAccountingDate.accept,
    filters.endAccountingDate.accept
  ),
  plu: undefinedIfEmpty(filters.plu.join()),
  groupBy: undefinedIfEmpty(filters.thirdPartySalesByItemsGroupBy.join()),
  sentToOnedrive,
});

export const findThirdPartySalesByItemsResponseMap = (
  response: FindThirdPartySalesByItemsResponse
): ThirdPartySalesByItems[] =>
  response.map(i => ({
    store: i.store,
    accountingDate: dateResponseMap(i.accounting_date),
    plu: i.plu,
    shortName: i.short_name,
    quantitySold: i.quantity_sold,
    quantityPromo: i.quantity_promo,
    quantityMeal: i.quantity_meal,
    quantityWaste: i.quantity_waste,
    grossSaleAd: i.gross_sale_ad,
    calculatedNetSale: i.calculated_net_sale,
    netSaleAd: i.net_sale_ad,
    saleMixPercentage: i.sale_mix_percentage,
    dateFrom: dateResponseMap(i.date_from) as Date,
    dateTo: dateResponseMap(i.date_to) as Date,
  }));

export const findMastersStatusRequestMap = (importDate: Date): FindMastersStatusDetailsRequest => ({
  importDate: dateRequestMap(importDate),
});

export const findMastersStatusDetailResponseMap = (
  response: FindMastersStatusDetailsResponse
): MasterStatusDetail[] =>
  response.map(i => ({
    masterType: i.master_type,
    creationDate: dateTimeResponseMap(i.creation_date),
    error: i.error,
    registerQty: i.register_qty,
    status: i.status,
  }));

const findPettyCashDifferenceBaseResponseMap = (
  item: FindPettyCashDifferenceBaseResponse
): PettyCashDifference => ({
  country: item.country,
  store: item.store,
  businessDate: dateResponseMap(item.business_date) as Date,
  operationDate: dateResponseMap(item.operation_date) as Date,
  transferId: item.transfer_id,
  transferTypeId: item.transfer_type_id,
  transferTime: item.transfer_time,
  posNum: item.pos_num,
  shiftNum: item.shift_num,
  userId: item.user_id,
  username: item.username,
  employeeId: item.employee_id,
  employeeName: item.employee_name,
  totalAmt: item.total_amt,
});

export const findPettyCashDifferenceRequestMap = (
  filters: ReportFilter,
  collectionsFilters: CollectionsFilter,
  sentToOnedrive: boolean
): FindPettyCashDifferenceRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    collectionsFilters.startBusinessDate.accept,
    collectionsFilters.endBusinessDate.accept
  ),
  differenceType: undefinedIfEmpty(filters.differenceType),
  sentToOnedrive,
});

export const findPettyCashDifferenceResponseMap = (
  response: FindPettyCashDifferenceResponse
): PettyCashDifference[] =>
  response.map(i => ({
    ...findPettyCashDifferenceBaseResponseMap(i),
  }));

export const findPettyCashDifferenceByPaymentTypeRequestMap = (
  filters: ReportFilter,
  collectionsFilters: CollectionsFilter,
  sentToOnedrive: boolean
): FindPettyCashDifferenceByPaymentTypeRequest => ({
  ...findPettyCashDifferenceRequestMap(filters, collectionsFilters, sentToOnedrive),
  tenderName: undefinedIfEmpty(filters.paymentTypes.map(i => i.tenderId).join()),
});

export const findPettyCashDifferenceByPaymentTypeResponseMap = (
  response: FindPettyCashDifferenceByPaymentTypeResponse
): PettyCashDifferenceByPaymentType[] =>
  response.map(i => ({
    ...findPettyCashDifferenceBaseResponseMap(i),
    tenderName: i.tender_name,
    amount: i.amount,
    foreignAmount: i.foreign_amount,
    fcRate: i.fc_rate,
  }));

export const findEconomicAccountingReportRequestMap = (
  filters: ReportFilter,
  collectionsFilters: CollectionsFilter,
  sentToOnedrive: boolean
): FindEconomicAccountingReportRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    collectionsFilters.startBusinessDate.accept,
    collectionsFilters.endBusinessDate.accept
  ),
  payment_type_ids: undefinedIfEmpty(filters.paymentTypes.map(i => i.paymentTypeId).join()),
  only_diff: filters.onlyDifferences,
  sentToOnedrive,
});

export const findEconomicAccountingReportResponseMap = (
  response: FindEconomicAccountingReportResponse
): EconomicAccountingReport[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    businessDate: dateResponseMap(i.business_date) as Date,
    tenderName: i.tender_description,
    paymentType: i.payment_type_id,
    grossSale: i.gross_sales,
    cashierDifference: i.cashier_difference,
    excess: i.excess,
    pettyCashExtraction: i.petty_cash_extraction,
    theoricalBalanceReca: i.deposit_amount_reca,
    extraCash: i.extracash,
    difference: i.diferencia,
  }));

export const findCashMovementReportRequestMap = (
  filters: ReportFilter,
  collectionsFilters: CollectionsFilter,
  sentToOnedrive: boolean
): FindCashMovementReportRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    collectionsFilters.startBusinessDate.accept,
    collectionsFilters.endBusinessDate.accept
  ),
  payment_type_ids: undefinedIfEmpty(filters.paymentTypes.map(i => i.paymentTypeId).join()),
  movements_type: undefinedIfEmpty(collectionsFilters.movementType.join()),
  sentToOnedrive,
});

export const findCashMovementReportResponseMap = (
  response: FindCashMovementReportResponse
): CashMovementReport[] =>
  response.map(i => ({
    country: i.pais,
    store: i.local,
    businessDate: dateResponseMap(i.Fecha_de_Negocio) as Date,
    referenceNumber: i.Nro_Referencia,
    movementNumber: i.Nro_Movimiento,
    paymentMethod: i.Moneda,
    movementType: i.Tipo_Movimiento,
    timeOfMovement: i.Hora_Movimiento,
    userId: i.Id_Usuario,
    userName: i.Nombre_Usuario,
    movementCode: i.Codigo_Movimiento,
    theoreticalBalanceReca: i.Deposito_Teorico,
    recovery: i.Recupero,
    pettyCashExtraction: i.Extracion_Caja_Chica,
    exchangePurchase: i.Compra_Cambio,
    cashCount: i.Arqueo_Caja,
    cashCountDifference: i.Diferencia_Arqueo,
  }));

export const findMastersStatusResponseMap = (
  response: FindMastersStatusResponse
): MastersStatus[] =>
  response.map(i => ({ importDate: dateResponseMap(i.import_date) as Date, status: i.status }));
export const findSalesJournalRequestMap = (
  filters: ReportFilter,
  dataType: string
): FindSalesJournalRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  accountingDateRange: dateRangeRequestMap(
    filters.startAccountingDate.accept,
    filters.endAccountingDate.accept
  ),
  dataType,
});

export const findSalesJournalGcsResponseMap = (
  response: FindSalesJournalResponse
): SalesJournalGcs[] =>
  response.map(i => ({
    countryId: i.country_id as string,
    store: i.store as string,
    accountingDate: dateResponseMap(i.accounting_date) as Date,
    posNum: i.pos_num as number,
    fiscalPosCode: i.fiscal_pos_code as string,
    from: i.from as number,
    to: i.to as number,
    grossSale: i.gross_sale as number,
    ivaPercentage: i.iva_percentage as number,
    iva: i.iva as number,
    netSale: i.net_sale as number,
  }));

export const findSalesJournalNcsResponseMap = (
  response: FindSalesJournalResponse
): SalesJournalNcs[] =>
  response.map(i => ({
    invoiceType: i.invoice_type as string,
    invoiceKind: i.invoice_kind as string,
    fiscalPosCode: i.fiscal_pos_code as string,
    fiscalReceiptNum: i.fiscal_receipt_num as number,
    fiscalRegisterId: i.fiscal_register_id as string,
    fiscalName: i.fiscal_name as string,
    grossSale: i.gross_sale as number,
    ivaPercentage: i.iva_percentage as number,
    iva: i.iva as number,
    netSale: i.net_sale as number,
  }));

export const findSalesJournalFcsResponseMap = (
  response: FindSalesJournalResponse
): SalesJournalFcs[] =>
  response.map(i => ({
    invoiceType: i.invoice_type as string,
    invoiceKind: i.invoice_kind as string,
    fiscalPosCode: i.fiscal_pos_code as string,
    fiscalReceiptNum: i.fiscal_receipt_num as number,
    fiscalRegisterId: i.fiscal_register_id as string,
    fiscalName: i.fiscal_name as string,
    grossSale: i.gross_sale as number,
    ivaPercentage: i.iva_percentage as number,
    iva: i.iva as number,
    netSale: i.net_sale as number,
  }));

export const findSalesJournalThirdPartyResponseMap = (
  response: FindSalesJournalResponse
): SalesJournalThirdParty[] =>
  response.map(i => ({
    grossSale: i.gross_sale as number,
    ivaPercentage: i.iva_percentage as number,
    iva: i.iva as number,
    netSale: i.net_sale as number,
  }));

export const findDiscountsRequestMap = (
  filters: ReportFilter,
  gscFilters: GcsFilter
): FindDiscountsRequest => ({
  countryAcronym: filters.countryCode,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    gscFilters.startBusinessDate.accept,
    gscFilters.endBusinessDate.accept
  ),
  accountingDateRange: dateRangeRequestMap(
    gscFilters.startAccountingDate.accept,
    gscFilters.endAccountingDate.accept
  ),
  operationDateRange: dateRangeRequestMap(
    gscFilters.startOperationDate.accept,
    gscFilters.endOperationDate.accept
  ),
});

export const findDiscountsResponseMap = (response: FindDiscountsResponse): Discount[] =>
  response.map(i => ({
    idDiscount: i.id_discount,
    description: i.description,
    transactionType: i.transaction_type,
  }));

export const findMastersImportRequestMap = (
  filters: MastersImportDetailFilter
): FindMastersImportRequest => ({
  countries: undefinedIfEmpty(filters.countries.map(i => i.countryId).join()),
  importDateRange: dateRangeRequestMap(
    filters.startImportDate.accept,
    filters.endImportDate.accept
  ),
  state: undefinedIfEmpty(filters.state.join()),
});

export const findMastersImportResponseMap = (
  response: FindMastersImportResponse,
  countries: Country[]
): MastersImport[] =>
  response
    .map(i => ({
      importDate: dateResponseMap(i.import_date) as Date,
      type: i.type,
      country: countries.find(c => c.countryId === i.id)?.countryCode || i.id,
      description: i.description,
      quantity: i.quantity,
      state: i.state,
    }))
    .sort(masterSort);

export const findMastersImportExecutionRequestMap = (
  request: MasterImportExecutionForm
): MastersImportRequest => ({
  master_type: request.mastersTypes,
  countries: request.countries?.map(i => i.countryId).join(','),
  days: request.days,
  only_active: true,
});
export const findPendingInterfacesRequestMap = (
  filters: PendingInterfacesFilter
): FindPendingInterfacesRequest => ({
  countryAcronym: filters.country,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDateRange: dateRangeRequestMap(
    filters.startBusinessDate.accept,
    filters.endBusinessDate.accept
  ),
  queryFilter: filters.queryFilter,
});

export const findPendingInterfacesResponseMap = (
  response: FindPendingInterfacesResponse
): PendingInterfaces[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    businessDate: dateResponseMap(i.business_date) as Date,
    dataEntry: i.data_entry,
    workingDay: i.working_day,
    salesClose: i.sales_close,
    salesCause: i.sales_cause,
    salesDetails: i.sales_details,
    collClose: i.coll_close,
    collCause: i.coll_cause,
    collDetails: i.coll_details,
    whData: i.wh_data,
    grSalesProccesed: i.gr_sales_processed,
    grSalesSent: i.gr_sales_sent,
    grStatus: i.gr_status,
    groupedTotalSales: i.grouped_total_sales,
    groupedSalesByPayment: i.grouped_sales_by_payment,
    groupedSalesByProduct: i.grouped_sales_by_product,
    lastUpdate: i.last_update,
  }));

export const createPendingInterfacesRequestMap = (
  request: CreatePendingInterfacesForm
): CreatePendingInterfacesRequest => ({
  countryAcronym: request.country || '',
  justification: request.justification,
  stores: (request.stores || []).map(i => i.storeAcronym),
  businessDateFrom: dateRequestMap(request.businessDateFrom) as string,
  businessDateTo: dateRequestMap(request.businessDateTo) as string,
});

export const executionPendingInterfacesRequestMap = (
  filters: ExecutionStatusPendingInterfacesFilter
): ExecutionPendingInterfacesRequest => ({
  countryAcronym: filters.country,
  storeAcronym: undefinedIfEmpty(filters.stores.map(i => i.storeAcronym).join()),
  businessDate: dateRequestMap(filters.businessDate.accept),
  state: filters.status,
});

export const executionPendingInterfacesResponseMap = (
  response: ExecutionPendingInterfacesResponse
): ExecutionStatusPendingInterfaces[] =>
  response.map(i => ({
    country: i.country,
    store: i.store,
    businessDate: dateResponseMap(i.business_date) as Date,
    justification: i.justification,
    executionDate: i.execution_date,
    state: i.status,
  }));

export const findPendingInterfacesParametersResponseMap = (
  response: FindPendingInterfacesParametersResponse
): PendingInterfacesParameters => ({
  statuses: response.statuses,
  filterQuerys: response.filterQuerys,
});
